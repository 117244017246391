<template>
<div class="">

  <div class="lg:min-h-screen flex items-center justify-center py-12 lg:-mt-10 px-4 sm:px-6 lg:px-8">
  <div class="max-w-md w-full space-y-8">
    <div>
      <img class="mx-auto w-3/4 pb-6" src="https://tbff-assets.ams3.cdn.digitaloceanspaces.com/images/lbsqtrans.png">
      <h2 class="mt-6 text-center text-xl font-extrabold text-lborange">
        Register for an account
      </h2>
    </div>
    <form class="mt-8 space-y-6" ref="form" @submit.prevent="registerUser">
      <input type="hidden" name="remember" value="true">
      <div class="rounded-md shadow-sm -space-y-px">
        <div>
          <label for="name" class="sr-only">Name</label>
          <input v-model="name" id="name" name="name" type="text" autocomplete="Name" required class="appearance-none rounded-none relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 rounded-t-md focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 focus:z-10 sm:text-sm" placeholder="Name">
        </div>
        <div>
          <label for="email-address" class="sr-only">Email address</label>
          <input v-model="email" id="email-address" name="email" type="email" autocomplete="email" required class="appearance-none rounded-none relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 focus:z-10 sm:text-sm" placeholder="Email address">
        </div>
        <div>
          <label for="password" class="sr-only">Password</label>
          <input v-model="password" id="password" name="password" type="password" autocomplete="current-password" required class="appearance-none rounded-none relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 rounded-b-md focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 focus:z-10 sm:text-sm" placeholder="Password">
        </div>
      </div>

      <!--<div class="flex items-center justify-center ">
          <div class="text-sm">
          <a href="#" class="font-medium text-indigo-600 hover:text-indigo-500">
            Forgot your password?
          </a>
        </div>
      </div>-->

      <div>
        <button type="submit" class="group relative w-full flex justify-center py-2 px-4 border border-transparent text-sm font-medium rounded-md text-white bg-lborange hover:bg-lbg focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-lbf">
          Register
        </button>
      </div>
    </form>

  </div>

</div>


<FlashMessage :error="error" @close="error = null" />
</div> 
</template>

<script>

import { getError } from "@/utils/helpers";
import AuthService from "@/services/AuthService";
import FlashMessage from "@/components/FlashMessage";

export default {
    name: 'Register',
  components: {
    FlashMessage,
  },
  data () {
    return {
      name: null,
      email: null,
      password: null,
      passwordConfirm: null,
      error: null,
      message: null,
    }
  },
  methods: {
      registerUser() {
        this.error = null;
        this.message = null;
        const payload = {
          name: this.name,
          email: this.email,
          password: this.password,
          password_confirmation: this.password,
        };
        AuthService.registerUser(payload)
          .then(() => this.$router.push("/"))
          .catch((error) => (this.error = getError(error)));
      },
  },
}
</script>